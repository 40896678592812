.item-card {
  &.card-transform,
  &:not(.item-card-disabled) {
    &:hover {
      transform: translateY(-5px);
    }
  }
  &.card-shadow,
  &:not(.item-card-disabled) {
    &:hover {
      box-shadow: 0 4px 25px 0 rgba($black, 0.25);
    }
  }
  &.item-card-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}


// Grid View Specific style
.grid-view {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2rem;

  .item-card {
    overflow: hidden;

    // Item Image
    .item-img {
      min-height: 15.85rem;
      max-height: 250px;
      display: flex;
      align-items: center;
      overflow: hidden;
      object-fit: cover;

      @include media-breakpoint-down(sm) {
        width: 100%;
        min-height: 50px;
        max-height: 50px;
        max-width: 50px;
        flex-shrink: 1;
      }
    }

    // Item content wrapper
    .item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    // Hiding list view items
    .shipping,
    .item-company,
    .item-options .item-price {
      display: none;
    }

    .item-options {
      display: flex;
      flex-wrap: wrap;

      .btn-cart,
      .btn-wishlist {
        flex-grow: 1;
        border-radius: 0;
      }
    }

    .item-name {
      margin-top: 0.75rem;
    }

    // Item Description
    .item-description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 0.2rem;
    }

    .item-price {
      font-weight: $font-weight-bolder;
    }

    .card-body {
      padding: 1rem;
    }
  }
}

.list-view {
  display: grid;
  grid-template-columns: 1fr;

  .item-card {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    // list view image
    .item-img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .card-body {
      padding: 1.5rem 1rem;
      border-right: 1px solid $border-color;
      display: flex;
      flex-direction: column;

      // item name and ratings order
      .item-wrapper {
        order: 2;
      }
      .item-name {
        order: 1;
      }
      // List view description
      .item-description {
        order: 3;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      // .item-rating,
      .item-price {
        display: none;
      }
      // ratings
      .item-rating {
        margin-bottom: 0.3rem;
      }
    }
    // Common for list-view, checkout and wishlist
    .item-company {
      display: inline-flex;
      font-weight: $font-weight-normal;
      margin: 0.3rem 0 0.5rem;
      font-size: 0.875rem;

      .company-name {
        font-weight: $font-weight-bolder;
        margin-left: 0.25rem;
      }
    }

    // price and btn area of list view
    .item-options {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .item-wrapper {
        position: relative;

        .item-cost {
          .item-price {
            color: $primary;
            margin-bottom: 0;
          }
        }
      }
      // Shipping free tag
      .shipping {
        margin-top: 0.75rem;
      }
      // List view btn spacing
      .btn-wishlist,
      .btn-cart {
        margin-top: 1rem;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .grid-view {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1500px) {
  .grid-view {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@include media-breakpoint-down(xl) {
  .grid-view {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@include media-breakpoint-down(lg) {
  .grid-view {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@include media-breakpoint-down(md) {
  .grid-view {
    grid-template-columns: 1fr 1fr;
  }
  // List View
  .list-view {
    .item-card {
      grid-template-columns: 1fr 1fr;

      .item-img {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      // to remove card-body border
      .card-body {
        border: none;
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  // Grid View
  .grid-view,
  .grid-view.wishlist-items {
    grid-template-columns: 1fr;
  }
}
