//Container
.login-container {
  @extend .d-flex;
  @extend .flex-column;
  @extend .position-relative;

  gap: 2rem;

  &.centralized {
    @extend .align-items-center;
  }
}
// Wrapper
.login-wrapper {
  @extend .d-flex;
  @extend .flex-column;
  @extend .gap-1;
  @extend .w-100;
  padding: 0 2rem;

  .login-backward-button {
    @extend .position-absolute;
    z-index: 2;
    margin-left: 1rem;
    left: 0;
    top: -0.5rem;
  }

  @include media-breakpoint-down(md) {
    padding: 0 1rem;
  }
}
// Header
$login-backward-button-size: 34.2px;
.login-header {
  @extend .login-wrapper;
  padding: 0 calc(1rem + #{$login-backward-button-size} + 0.25rem);

  @include media-breakpoint-down(sm) {
  }
}

// Content
.login-content {
  @extend .d-flex;
  @extend .flex-column;
  @extend .gap-1;
  @extend .h-100;

  &.otp_verification {
    @extend .justify-content-between;
    max-width: calc(302.25px + 4rem);

    @include media-breakpoint-down(sm) {
      max-width: calc(302.25px + 2rem);
    }
  }
}

//Footer
.login-footer {
  @extend .d-flex;
  @extend .bg-light;
  @extend .py-50;
  @extend .pt-1;
  @extend .p-2;
  @extend .w-100;
  @extend .align-items-center;
  @extend .justify-content-center;

  // margin-top: 1rem;
  border-radius: 0 0 0.357rem 0.357rem;

  &.otp_verification {
    border-radius: none;
    margin-top: 0;
  }
}

//Components
.login-button-handler {
  @extend .d-flex;
  @extend .gap-1;
  @extend .w-100;
  @extend .align-items-center;
  @extend .justify-content-center;

  &.outline-backward-button {
    border-style: solid;
    border-width: 1px;
    border-color: #f6f6f6;

    &:hover {
      border-color: #e6e6e6;
    }
  }
}

.fullscreen-login-layout {
  @extend .d-flex;
  @extend .flex-column;
  @extend .align-items-center;
  @extend .justify-content-center;

  background-color: white;

  min-height: 100svh;
  height: 100%;
}

.fullscreen-login-container {
  @extend .d-flex;
  @extend .flex-column;
  @extend .h-100;
  @extend .w-100;
}

.fullscreen-login-content-wrapper {
  @extend .d-flex;
  @extend .flex-column;
  @extend .flex-fill;
  @extend .justify-content-center;
  @extend .align-items-center;
}

.fullscreen-login-content-row {
  @extend .d-flex;
  @extend .w-100;
  max-width: 1050px;
  padding: 2rem;

  > *:nth-child(even) {
    padding-right: 0rem;
    padding-left: 2rem;
  }
  > *:nth-child(odd) {
    padding-right: 2rem;
    padding-left: 0rem;
  }
}

$fullscreen-login-content-height: 575px;
.fullscreen-login-content {
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .w-100;
  @extend .h-100;
  @extend .m-0;
  @extend .p-0;
  @extend .py-md-2;
  max-width: 375px;

  @include media-breakpoint-up(md) {
    min-height: $fullscreen-login-content-height;
  }
}

@include media-breakpoint-down(md) {
  .fullscreen-login-content-row {
    height: 100%;
    padding: 0 0;

    > *:nth-child(even) {
      padding-right: 0rem;
      padding-left: 0rem;
    }
    > *:nth-child(odd) {
      padding-right: 0rem;
      padding-left: 0rem;
    }
  }

  .fullscreen-login-content {
    padding: 1.5rem 0rem !important;
    max-height: $fullscreen-login-content-height;
    min-height: none;
  }
}


.login-kyc-row {
  > *:nth-child(even) {
    padding-left: 0.5rem;
  }
  > *:nth-child(odd) {
    padding-right: 0.5rem;
  }
}
