//Button Typography

.button-big{
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;

  color: #454545;
}

.button-medium{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #454545;
}

.button-small{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;

  color: #A19D9D;
}