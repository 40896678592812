//buttons
.button-icon{
  display: inline-block;
  background: #f1f1f1;
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0 5px 15px -5px #00000070;
  overflow: hidden;
  position: relative;
  transition: 0.2s linear;

  svg {
    line-height: 90px;
    font-size: 26px;
    transition: 0.2s linear;
    color:  rgb(0, 159, 227);

  }

  &:hover {
    svg {
      transform: scale(1.1);
      color: #6E6B7B;
    }
  }

  &.twitter{
    order: 1;
  }
  &.instagram{
    order: 2;
  }
  &.website{
    order: 3;
  }
  &.facebook{
    order: 4;
  }
  &.linkedin{
    order: 5;
  }
}
